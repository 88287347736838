import React,  { ReactDOM,useState,useRef,useEffect } from 'react';
import './todo.scss'; // Import your CSS styles
import { Resizable } from 'react-resizable';



const TodoList = ({ tasks, setTasks }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [remainingTasks, setRemainingTasks] = useState();
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const addTaskInput = useRef();
  
 const updateTasks = (newTasks) => {
    setTasks(newTasks);
  };

  useEffect(() => {
    setRemainingTasks(tasks.filter((taskItem) => !taskItem.done).length);
    updateTasks(tasks); // Notify the parent component about the updated tasks
  }, [tasks, updateTasks]);


  useEffect(() => {
    // Update viewport dimensions on window resize
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <div 
      className={`draggable ${isDragging ? 'dragging' : ''}`}    >
      <div  className="main">
      <div style={{height:viewportHeight/2}} className="todo">
        <div className="todo-header">
          <small>
            {tasks.length > 0 && remainingTasks === 0 ? (
              "All done! =D"
            ) : (
              <>
                You have <b>{remainingTasks}</b> of <b>{tasks.length}</b> tasks
                remaining
              </>
            )}
          </small>
        </div>
        <div className="todo-form">
          <input
            ref={addTaskInput}
            type="text"
            placeholder="Add task..."
          />
          <button onClick={addTask}>+</button>
        </div>
        <div className="todo-body">
          <List tasks={tasks} setTasks={setTasks} />
        </div>
      </div>
    </div>
    </div>
  );

  function addTask() {
    const newTask = addTaskInput.current.value;
    if (newTask && !tasks.some((task) => task.text === newTask)) {
      const newTaskObj = {
        text: newTask,
        done: false,
      };
      setTasks((tasks) => {
        return [...tasks, newTaskObj];
      });
      addTaskInput.current.value = "";
      addTaskInput.current.focus();
    }
  }

}
const List = ({ tasks, setTasks }) => {
  if (tasks.length > 0) {
    return (
      <ul className="todo-list">
        {tasks.map((task, taskIndex) => {
          return (
            <Task
              key={taskIndex}
              taskId={taskIndex}
              task={task}
              setTasks={setTasks}
            />
          );
        })}
      </ul>
    );
  }
  return (
    <div className="empty">
      <p>
        <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
          <g
            fill="none"
            fill-rule="evenodd"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            transform="translate(2.5 4.5)"
          >
            <path d="m3.65939616 0h8.68120764c.4000282 0 .7615663.23839685.9191451.6060807l2.7402511 6.3939193v4c0 1.1045695-.8954305 2-2 2h-12c-1.1045695 0-2-.8954305-2-2v-4l2.74025113-6.3939193c.15757879-.36768385.51911692-.6060807.91914503-.6060807z" />
            <path d="m0 7h4c.55228475 0 1 .44771525 1 1v1c0 .55228475.44771525 1 1 1h4c.5522847 0 1-.44771525 1-1v-1c0-.55228475.4477153-1 1-1h4" />
          </g>
        </svg>
      </p>
      <p>
        <b>Empty list</b>
      </p>
      <p>Add a new task above</p>
    </div>
  );
};

const Task = ({ taskId, task, setTasks }) => {
  function removeTask() {
    setTasks((tasks) => {
      return tasks.filter((taskItem, taskIndex) => taskIndex !== taskId);
    });
  }

  function toggleTask() {
    const doneStatus = !task.done;
    setTasks((tasks) => {
      return tasks.map((taskItem, taskIndex) => {
        if (taskIndex === taskId) {
          return {
            text: task.text,
            done: doneStatus,
          };
        }
        return taskItem;
      });
    });
  }

  const prefixClass = "task-item";
  const doneClass = task.done ? " done" : "";

  return (
    <li className={prefixClass + doneClass}>
      <div className={prefixClass + "-infos"}>
        <label className={prefixClass + "-checkbox"}>
          <input type="checkbox" onChange={toggleTask} checked={task.done} />
          <div className={prefixClass + "-checkbox-el"}></div>
        </label>
        <div className={prefixClass + "-text"}>{task.text}</div>
      </div>
      <div className={prefixClass + "-remove"}>
        <button onClick={removeTask} title="Remover item">
          <svg
            height="21"
            viewBox="0 0 21 21"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              fill="none"
              fill-rule="evenodd"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(5 5)"
            >
              <path d="m10.5 10.5-10-10z" />
              <path d="m10.5.5-10 10" />
            </g>
          </svg>
        </button>
      </div>
    </li>
  );
};

 

export default TodoList;