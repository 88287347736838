
import React, { useState, useEffect, useRef } from 'react';
import YouTube from 'react-youtube';
import { IconContext } from 'react-icons';
import { BiSkipPrevious, BiSkipNext, BiPlay, BiPause, BiWorld } from 'react-icons/bi';
import {  BsSun, BsMoonStars } from 'react-icons/bs';
import {LuListTodo } from 'react-icons/lu';
import { Container, Row, Col } from 'reactstrap';
import { lofilogo2w,lofilogoblue, lofilogopeach } from '../assets';
import { Rnd } from 'react-rnd';
import Draggable from 'react-draggable'; // The default
import { TodoList, CountrySelect } from '.';
require('dotenv').config();

function YoutubePlayer(props)  {
  const { onNightTimeChange, todoListComponent  } = props;
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [showTodoList, setShowTodoList] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [playlistId, setPlaylistId] = useState('');
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const nightTimeRef = useRef(true); // Initialize with the default value
  const forceUpdate = useRef(() => {}); //
  const [isNight, setIsNight] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState('Japan');
  const [tasks, setTasks] = useState([]);

  const updateTasks = (newTasks) => {
    setTasks(newTasks);
  };

  const toggleTodoList = () => {
    setShowTodoList((prevShowTodoList) => !prevShowTodoList);
  };

  const setNightTime = (value) => {
    nightTimeRef.current = value;
    forceUpdate.current(); 
    onNightTimeChange(value);
  };

  const handleCountryChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedCountry(selectedValue);
    setCurrentVideoIndex(0);
  };

 const setPlaylistByCountry = (country) => {
  let playlistId;
  console.log(nightTimeRef,'before')
  
  //YOU NEED USEREF
  
  switch (true) {
    case  nightTimeRef.current && country === 'Switzerland':
      playlistId = 'PLd_a622h4YfvJz9oyb8RngspkUY6RInfk';
      console.log( nightTimeRef.current,country,'1')
      break;
    case ! nightTimeRef.current && country === 'Switzerland':
      playlistId = 'PLd_a622h4Yfsx9lrz8QHHrfE52wtTgzYS';
      console.log( nightTimeRef.current,country,'2')
      break;
    case  nightTimeRef.current && country === 'Japan':
      playlistId = 'PLd_a622h4YftyLtkLyfLkj5HBHETDnbg4';
      console.log( nightTimeRef.current,country,'3')
      break;
    case ! nightTimeRef.current && country === 'Japan':
      playlistId = 'PLd_a622h4YftYHQMvJHa4aT0PgwIF3nzy';
      console.log( nightTimeRef.current,country,'4')
      break;
    case  nightTimeRef.current && country === 'Portland':
      playlistId = 'PLd_a622h4YftSFyiKuoSlqfvjuCSWNBzm';
      console.log( nightTimeRef.current,country,'5')
      break;
    case ! nightTimeRef.current && country === 'Portland':
      playlistId = 'PLd_a622h4Yfvklbr2oNg7YB-VY8E9FStI';
      console.log( nightTimeRef.current,country,'6')
      break;
    case  nightTimeRef.current && country === 'England':
      playlistId = 'PLd_a622h4Yfuj8FIYXQYQMt6_Li0OLLhm';
      console.log( nightTimeRef.current,country,'7')
      break;
    case ! nightTimeRef.current && country === 'England':
      playlistId = 'PLd_a622h4YfsaZbo1laT9chlbn4voi6G0';
      console.log( nightTimeRef.current,country,'8')
      break;
    case  nightTimeRef.current && country === 'Colorado':
      playlistId = 'PLd_a622h4YfsNyKOofmNoRPZJoQb147DY';
      console.log( nightTimeRef.current,country,'9')
      break;
    case ! nightTimeRef.current && country === 'Colorado':
      playlistId = 'PLd_a622h4YfuCof7iXMxVlf0CjOLi-i_0';
      console.log( nightTimeRef.current,country,'10')
      break;
    case  nightTimeRef.current && country === 'South Korea':
      playlistId = 'PLd_a622h4YfsiChCGhUxOI03863qMMnnz';
      console.log( nightTimeRef.current,country,'9')
      break;
    case ! nightTimeRef.current && country === 'South Korea':
      playlistId = 'PLd_a622h4YftzRgGf1eEmgGGsyOUA4mhi';
      console.log( nightTimeRef.current,country,'10')
      break;
    default:
      playlistId = ''; // Default value if no case matches
  }
setCurrentVideoIndex(0);
  // Use the playlistId in your logic here
  setPlaylistId(playlistId);
  
};

useEffect(() => {
  forceUpdate.current = () => {
    // This will force the component to re-render
    // You can also use this function inside other useEffect or event handlers
    setPlaylistByCountry(selectedCountry);
  };
}, [selectedCountry]);

  useEffect(() => {
    setPlaylistByCountry(selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    // Fetch the playlist items from the YouTube Data API.
    fetchPlaylistItems(playlistId);
  }, [playlistId]);

  useEffect(() => {
    // Update viewport dimensions on window resize
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs only once, on component mount

  //RETURN TO THIS, PUT IN HIDDEN FILE AS CONSTANT
  const fetchPlaylistItems = async (playlistId) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=50&playlistId=${playlistId}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`
        );
      const data = await response.json();

      if (data.items) {
        const videoIds = data.items.map((item) => item.snippet.resourceId.videoId);
        setPlaylist(videoIds);
      }
    } catch (error) {
      console.error('Error fetching playlist:', error);
    }
  };

  const playNextVideo = () => {
    if (currentVideoIndex < playlist.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const playPreviousVideo = () => {
    if (currentVideoIndex !== 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };

  const onReady = (event) => {
    setPlayer(event.target);
  };

  const pauseVideo = () => {
    if (player) {
      player.pauseVideo();
      setIsPlaying(false);
    }
  };

  const resumeVideo = () => {
    if (player) {
      player.playVideo();
      setIsPlaying(true);
    }
  };

  const opts = {
    width: '100%',
    height: viewportWidth >= 992 ? viewportHeight/1.1 : viewportHeight/2, // Set height based on screen size
    playerVars: {
      controls: 0,
      showinfo: 1,
      modestbranding: 1,
      mute: 1,
      autoplay: 1,
    },
  };

  const onVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
  };

  const handleNightTimeChange = (value) => {
    setIsNight(value);
  };


  return (
    <Container fluid>
      <Row>
        <div style={{ height: '30px' }}></div>
      </Row>
      <Row>
        <Col md={12} lg={9} xl={10} >
          <div className="video-container">
            <YouTube videoId={playlist[currentVideoIndex]} opts={opts} onEnd={onVideoEnd} onReady={onReady} />
          </div>
        </Col>
        <Col style={{display:"flex", alignItems:"center", justifyContent:"center"}}xs={12} md={12} lg={3} xl={2}>
          <Row style={{alignItems:"center", display:"flex"}} >
          <Col xs={6} md={6} lg={12}>
            <Col xs={12} lg={12}><div className="logo">
              {nightTimeRef.current === true ?<img src={lofilogo2w} /> : <img src={lofilogopeach} />}

            </div>
            </Col>
            <Row style={{alignContent:"center", display:"flex"}} >
            <Col style={{paddingBottom:20, paddingTop:20, display:"flex",justifyContent:"center"}} xs={4} lg={4}>
              <button className={nightTimeRef.current === false ? "boopable" : "boopable2"}  
                onMouseDown={playPreviousVideo} type="primary">
                <BiSkipPrevious size={30} />
              </button>
            </Col>
            <Col style={{paddingBottom:20, paddingTop:20,display:"flex",justifyContent:"center"}} xs={4} lg={4}>
              <button 
              className={nightTimeRef.current === false ? "boopable" : "boopable2"} 
                onMouseDown={isPlaying ? pauseVideo : resumeVideo} type="primary">
                {isPlaying ? <BiPause className={!isPlaying ? "iconOn" : "iconOff"} size={30} /> : <BiPlay className={!isPlaying ? "iconOn " : "iconOff"} size={30} />}
              </button>
            </Col>
            <Col style={{paddingBottom:20, paddingTop:20, display:"flex",justifyContent:"center"}} xs={4} lg={4}>
              <button className={nightTimeRef.current === false ? "boopable" : "boopable2"}  onMouseDown={playNextVideo} type="primary">
                <BiSkipNext   size={30} />
              </button>
            </Col>
            </Row>
            <Row style={{paddingBottom:40, paddingTop:20}} >
            <Col style={{display:"flex",justifyContent:"center"}}  xs={4} lg={4}>
              <button className={nightTimeRef.current === false ? "boopable" : "boopable2"}  onMouseDown={()=> setNightTime(!nightTimeRef.current)} type="primary">
              {nightTimeRef.current === false ? <BsSun size={30} /> : <BsMoonStars size={30}/>}
              </button>
            </Col>
            <Col style={{display:"flex",justifyContent:"center"}} xs={4} lg={4}>
            <button
              className={nightTimeRef.current === false ? "boopable" : "boopable2"}
              onClick={toggleTodoList}
              type="primary"
            >
              <LuListTodo className={showTodoList ? "iconOnTask" : ""} size={30} />
            </button>
          </Col>
            <Col style={{display:"flex",justifyContent:"center"}} xs={4} lg={4}>
              <button className={nightTimeRef.current === false ? "boopable" : "boopable2"}   onMouseDown={()=> setShowCountrySelect(!showCountrySelect)} type="primary">
                <BiWorld size={30} />
              </button>
            </Col>
            </Row>
          </Col>
            <Rnd>
              <div className="" style={{ position: 'absolute', zIndex: 2 }}>
              {showTodoList && 
              <TodoList onNightTimeChange={handleNightTimeChange} tasks={tasks} setTasks={setTasks} />}
              </div>
            </Rnd>
            <Draggable><div className="draggable" style={{ position: 'absolute', zIndex: 2 }}>{showCountrySelect ?  
            <CountrySelect
              showCountrySelect={showCountrySelect}
              selectedCountry={selectedCountry}
              handleCountryChange={handleCountryChange}
            />: null} </div></Draggable>
          <Col xs={6} md={6} lg={12} style={{display:"flex", alignContent:"center", paddingLeft:0, height: '100%' }}>
            <iframe
              style={{ border:"none", borderRadius: 12, flex: 1, height: viewportHeight/2.5 }}
              title="Lofi-Wanderer"
              src="https://open.spotify.com/embed/playlist/45tebamYyAivKDzkiTwasQ"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </Col>
            
            </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default YoutubePlayer;