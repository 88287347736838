import React, { useState, useContext, useEffect, Fragment } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import _ from "lodash";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import { api, constants, helpers, UserContext, storage} from "./utils";
import { Footer, SidebarMenu } from "./layouts";
import {
  Admin,
  Home,
  UserProfile,
  SignIn,
  User,
  Users,
  ReferenceDataList,
  ResetPassword,
  ForgotPassword,
  UpdatePassword
} from "./components";

const nowTime = () => new Date().getTime();
const { THEMES } = constants;

export default function App() {
  const userCtx = useContext(UserContext);
  const tokenFromStorage = api.userToken() || null;
  const parsedUser = tokenFromStorage
    ? JSON.parse(storage.getItem("currentUser"))
    : null;
  const [authToken, setAuthToken] = useState(tokenFromStorage);
  const [currentUser, setCurrentUser] = useState(parsedUser);
  const [impersonating, setImpersonating] = useState();
  const [theme, setTheme] = useState(THEMES.default);
  const [alertMessage, setAlertMessage] = useState(null);
  const [onPublicPage, setOnPublicPage] = useState(true);
  const [isNight, setIsNight] = useState(true);
  const navigate = useNavigate();

  function unSetTetheringAndRedirect(path) {
    storage.removeItem("untethering");
    navigate("/");
  }

  document.addEventListener('DOMContentLoaded', function () {
    if (isMobileDevice()) {
      showMobilePopup();
    }
  });
  
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  
  function showMobilePopup() {
    var modal = document.getElementById('mobilePopup');
    modal.style.display = 'block';
  }
  
  function closeMobilePopup() {
    var modal = document.getElementById('mobilePopup');
    modal.style.display = 'none';
  }

  const referencePathList = _.map(
    constants.REFERENCE_DATA_URL_LIST,
    (x) => x.reactPath
  );
  const showSidebar =
    userCtx && currentUser && currentUser.id;

    const handleNightTimeChange = (value) => {
      setIsNight(value);
    };
  

  return (
    <div className={`${theme} siteContainer fullHeight`}>
      <div id="mobilePopup" class="modal">
  <div class="modal-content">
    <button class="close" onClick={() => closeMobilePopup}></button>
    <p>This app is optimized for desktop and tablets. For the best experience, please use a larger screen.</p>
  </div>
</div>
        <div>
            <Fragment>
                <Routes>
                  <Route exact path="/home" element={<Home />} />
                  <Route exact path="/dashboard/:tabName" element={<Home />} />
                  <Route exact path="/user/:id" element={<User />} />
                  <Route exact path="/admin/users" element={<Users />} />
                  <Route exact path="/admin/:tabName" element={<Admin />} />
                  <Route exact path="/admin/menu" element={<Admin />} />
                  <Route exact path="/profile" element={<UserProfile />} />
                  <Route exact path="/" element={<Home />} />
                </Routes>
            </Fragment>
        </div>
        
    </div>
  );
}
