import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import { storage } from "../utils";

export default function Footer({ isNight, onNightTimeChange }) {

  let appVersion = "";
  try {
    appVersion = storage.getItem("app-version");
  } catch {}

  const handleNightTimeChange = (value) => {
    onNightTimeChange(value);
  };

  return (
    <div className={isNight ? "nightBackground" : "dayBackground"}>
      <Row className="m-0 px-0 pt-4 pb-5">
        <Col xs="1" />
        <Col xs="5" sm="6" md="7" className="px-2"></Col>
        <Col
          xs="6"
          sm="5"
          md="4"
          className="pl-0 pr-4 text-right align-self-center"
        >
          Lofi Wanderer &#169; 2023
        </Col>
      </Row>
    </div>
  );
}