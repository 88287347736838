import { React, useState } from "react";
import _ from "lodash";
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import Draggable from 'react-draggable'; // The default
import { FcTodoList, } from 'react-icons/fc';
import { BiWorld,BiSkipNext } from 'react-icons/bi';
import { IconContext } from "react-icons";
import { lofilogo2w } from "../assets";
import { Footer } from "../layouts";
import { YoutubePlayer, TodoList, CountrySelect,VideoControls } from ".";

export default function Home(props) {

 
  const [showTodoList, setShowTodoList] = useState(false);
  const [showCountrySelect, setShowCountrySelect] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('Japan');
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isNight, setIsNight] = useState(true); // New state to store isNight value

 const toggleTodoList = () => {
    setShowTodoList((prevShowTodoList) => !prevShowTodoList);
  };



  const handleNightTimeChange = (value) => {
    setIsNight(value);
  };

  return (
    <Container className={isNight ? "nightBackground" : "dayBackground"} fluid style={{ position: 'relative'}}>
      <Row>
        <Col md={12}>
          <div className="video-player">
            <YoutubePlayer
              selectedCountry={selectedCountry}
              onNightTimeChange={handleNightTimeChange}
            />
          </div>
        </Col>
      </Row>
      {/* ... rest of your code */}
      <Footer isNight={isNight} onNightTimeChange={handleNightTimeChange} />
    </Container>
    
  );
}
