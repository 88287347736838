import React,  { ReactDOM,useState } from 'react';
import './countrySelect.scss'; // Import your CSS styles

function CountrySelect({ selectedCountry, handleCountryChange }) {
  return (
    <div className="todo">
      <div style={{color:"#fff"}} className="todo-header">
        Select Location
      </div>
      <div className="todo-form"></div>
      <div className="country-body">      
        <select
          id="countryDropdown"
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option value="">Select a Location</option>
          <option value="Colorado">Colorado</option>
          <option value="England">England</option>
          <option value="Japan">Japan</option>
          <option value="South Korea">South Korea</option>
          <option value="Portland">Portland</option>
          <option value="Switzerland">Switzerland</option>
        </select>
      </div>
    </div>
  );
}

export default CountrySelect;